// src/pages/About.js
import React from 'react';
import '../styles/About.css'; // Ensure the import path to styles

const teamMembers = [
  { name: 'Yuval Laniado', role: 'CEO', image: 'yuval.jpg' },
  { name: 'Guy Laniado', role: 'CTO', image: 'guy.jpg' },
  { name: 'Dor Dekel', role: 'CMO', image: 'dor.jpeg' },
  { name: 'Amiram Cohen', role: 'Electrical Engineer', image: 'amiram.jpg' },
  { name: 'Guy Bortnikov', role: 'Software Development', image: 'guyb.jpg' },
  { name: 'Ido Dekel', role: 'Biotechnology Engineer', image: 'ido.jpeg' },
  { name: 'Naveh Shezifi', role: 'Industrial Designer', image: 'naveh.jpg' }
];

const About = () => {
  return (
    <div className="about-container">
      <h1>Our Story</h1>
      <p>Cloud Gardener was founded in 2023 with a mission to revolutionize indoor gardening through innovative technology and automation. Our journey began with a simple idea: to create a solution that makes indoor gardening accessible to everyone, regardless of their experience level.</p>
      
      <h1>Our Mission</h1>
      <p>Our mission is to empower individuals to cultivate their own indoor gardens with ease and confidence. We believe in harnessing the power of technology to simplify the gardening process, allowing people to enjoy the benefits of fresh, homegrown produce year-round.</p>
      
      <h1>Our Values</h1>
      <p><strong>Innovation:</strong> We continuously strive to push the boundaries of what's possible in indoor gardening.</p>
      <p><strong>Sustainability:</strong> We are committed to promoting sustainable practices that protect the environment and conserve natural resources.</p>
      <p><strong>Accessibility:</strong> We believe that everyone should have the opportunity to experience the joy of gardening, regardless of their background or circumstances.</p>
      <p><strong>Community:</strong> We foster a sense of community among indoor gardening enthusiasts, sharing knowledge, tips, and experiences to support one another on our gardening journeys.</p>
      
      <h1>AI and Plant Growth</h1>
      <p>At Cloud Gardener, we're exploring the potential of artificial intelligence and machine learning to optimize plant growth in indoor environments. By analyzing data collected from sensors and cameras, our AI-powered system can monitor plant health, adjust environmental conditions such as light, humidity, and temperature, and automate watering and fertilization processes. This intelligent approach allows for precise and efficient plant care, resulting in healthier and more bountiful harvests.</p>
      <p>Our technology leverages cutting-edge advancements in computer vision and data analytics to provide real-time insights and recommendations, ensuring that every plant receives the optimal care it needs. By integrating AI with IoT devices, we offer a seamless and intelligent gardening experience that adapts to the unique needs of each plant species.</p>

      <h1>Meet Our Team</h1>
      <div className="team-container">
        {teamMembers.map((member, index) => (
          <div key={member.name} className="team-member">
            <img src={require(`../assets/${member.image}`)} alt={member.name} className="team-image" />
            <p className="team-name">{member.name}</p>
            <p className="team-role">{member.role}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
