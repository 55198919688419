// src/pages/Contact.js
import React from 'react';
import ContactForm from '../components/ContactForm';
import '../styles/Contact.css';

const Contact = () => {
  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      <ContactForm />
    </div>
  );
};

export default Contact;
