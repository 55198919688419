import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';  
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import 'firebase/firestore'


const firebaseConfig = {
  apiKey: "AIzaSyBEmhbLpppZYqa3w7KdUuOZ7rAPF8nCc3U",
  authDomain: "couldgardener.firebaseapp.com",
  databaseURL: "https://couldgardener-default-rtdb.firebaseio.com",
  projectId: "couldgardener",
  storageBucket: "couldgardener.appspot.com",
  messagingSenderId: "1041272128184",
  appId: "1:1041272128184:web:f27b7ca6bf33fd08faea4d",
  measurementId: "G-YET312WB72"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app); 
const db = getFirestore(app);  
export { db, collection, addDoc, serverTimestamp };

