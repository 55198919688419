// src/pages/Home.js
import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../styles/Home.css';
import logo from '../assets/logo.jpeg';

const Home = () => {
  const navigate = useNavigate(); 

  const handleLoginClick = () => {
    navigate('/login'); 
  };

  return (
    <div className="home-container">
      <img src={logo} alt="Cloud Gardener Logo" className="home-logo" />
      <h1>Cloud Gardener</h1>
      <p>Welcome to Cloud Gardener, your best automated planting solution.</p>
      <div className="button-container">
        <button className="login-button" onClick={handleLoginClick}>Login</button>
      </div>
    </div>
  );
};

export default Home;
